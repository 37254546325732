@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-size: 16px;
}

@layer components {
  .section {
    @apply m-auto mb-16 mt-16 w-full space-y-5 px-[16px] md:px-[32px] lg:px-[116px] xl:mx-auto xl:max-w-[1208px] xl:p-0;
  }
  .film-container {
    @apply m-auto w-full px-[16px] md:px-[32px] lg:px-[116px] xl:px-[280px];
  }
  .amb-container {
    @apply m-auto w-full px-[16px] md:px-[32px] lg:px-[116px] xl:mx-auto xl:max-w-[1208px] xl:p-0;
  }
}

/* This custom class displays the radio button with a checkmarck inside. */
.custom-radio {
  appearance: none;
  outline: none;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
}

.custom-radio:checked {
  border-color: #333;
  background-color: #333;
}

.custom-radio:checked::before,
.custom-radio:checked::after {
  content: "";
  position: absolute;
  background-color: #fff;
}

.custom-radio:checked::before {
  border-radius: 40%;
  width: 3px;
  height: 6px;
  top: 10px;
  left: 6px;
  transform: rotate(-45deg);
}

.custom-radio:checked::after {
  border-radius: 40%;
  width: 3px;
  height: 10px;
  top: 6px;
  left: 11px;
  transform: rotate(45deg);
}

input::placeholder {
  color: rgb(128, 128, 128);
}

input:focus::placeholder {
  color: rgb(187, 187, 187);
}

.meta-same-h div div {
  line-height: 0.5rem;
}
.meta-same-h div div span {
  line-height: 0.8rem;
}

/* only for next Image component, it should be wraped in a div */
.gradient-filter span {
  background: linear-gradient(to bottom, #0000 0%, black 100%) !important;
}

.lowerSwiper {
  width: 100%;
  color: #bcbfc2;
}

.lowerSwiper .swiper-title {
  color: #9ea1a3;
}

.lowerSwiper .swiper-slide-thumb-active {
  color: white;
}
.lowerSwiper .swiper-slide-thumb-active .swiper-title {
  color: #d0d3d6;
}

.lowerSwiper .swiper-slide-thumb-active .accent {
  color: #ff646d;
}

.lowerSwiper .swiper-slide-thumb-active .thumb-image {
  background-color: white;
  padding: 5px 5px 0px 5px;
}

.lowerSwiper .swiper-slide-thumb-active .border-top-slide {
  border-color: white;
}
.lowerSwiper .border-top-slide {
  border-color: #7d8185;
}

.hero-swiper .swiper-slide .content {
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.8) 0%,
      rgba(0, 0, 0, 0.128) 100%
    ),
    rgba(14, 15, 15, 0.12);
}

.amb-gradient-dark {
  background: linear-gradient(
      180deg,
      rgba(14, 15, 15, 0.8) 0%,
      rgba(0, 0, 0, 0.128) 100%
    ),
    rgba(14, 15, 15, 0.12);
}


.auto-resizable-iframe {
  width: 100%;
  margin: 0px auto;
}

.auto-resizable-iframe > div {
  position: relative;
  height: 100%;
}

.auto-resizable-iframe iframe {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

/* AKZIDENZ GROTESK LIGHT */
@font-face {
  font-family: "AkzidenzGrotesk";
  font-weight: 300;
  src: url("../assets/fonts/AkzidenzGrotesk/AkzidenzGrotesk-Light.otf")
    format("opentype");
}

/* AKZIDENZ GROTESK REGULAR */
@font-face {
  font-family: "AkzidenzGrotesk";
  font-weight: 400;
  src: url("../assets/fonts/AkzidenzGrotesk/AkzidenzGrotesk-Regular.otf")
    format("opentype");
}

/* AKZIDENZ GROTESK MEDIUM */
@font-face {
  font-family: "AkzidenzGrotesk";
  font-weight: 600;
  src: url("../assets/fonts/AkzidenzGrotesk/AkzidenzGrotesk-Medium.otf")
    format("opentype");
}

/* AKZIDENZ GROTESK BOLD */
@font-face {
  font-family: "AkzidenzGrotesk";
  font-weight: 700;
  src: url("../assets/fonts/AkzidenzGrotesk/AkzidenzGrotesk-Bold.otf")
    format("opentype");
}

/* AKZIDENZ GROTESK EXTRABOLD */
@font-face {
  font-family: "AkzidenzGrotesk";
  font-weight: 800;
  src: url("../assets/fonts/AkzidenzGrotesk/AkzidenzGrotesk-Bold.otf")
    format("opentype");
}
